import proj4 from "proj4";
proj4.defs('EPSG:3031', '+proj=stere +lat_0=-90 +lat_ts=-71 +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs');

// Transforms lon/lat coords in the EPSG:4326 map projection to x/y coords in the EPSG:3031 map projection
function transformData(data) {
    if (!data || !Array.isArray(data.paths)) {
        console.error("Data or paths are undefined or paths is not an array:", data);
        return;
    }

    data.paths.forEach(function(path) {
        path.sealPath.dives.forEach(function(dive) {
            let lon = parseFloat(dive.coord[0]);
            let lat = parseFloat(dive.coord[1]);

            const xy = proj4('EPSG:4326', 'EPSG:3031', [lon, lat]);

            dive.coord = xy;
        });
    });

}

window.transformData = transformData;